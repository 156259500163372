.payment-loading {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.55);
  z-index: 88888888888888900;
}
.payment-loading .content {
  background-color: #fff;
  width: 62.5%;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0 4.12rem;
  padding-top: 2.2rem;
}
.payment-loading .content .header {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.95rem;
  color: #4a4a4a;
}
.payment-loading .content .header img {
  max-width: 100%;
  margin-bottom: 0.75rem;
}
.payment-loading .content .image-container {
  width: 50%;
  padding: 5px;
  background-color: #fff;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #a5a5a540;
  display: flex;
  align-items: center;
  justify-content: center;
}
.payment-loading .content .image-container img {
  width: 100%;
}

@keyframes lds-ellipsis3 {
  0%,
  25% {
    left: 32px;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    left: 32px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  75% {
    left: 100px;
  }
  100% {
    left: 168px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@-webkit-keyframes lds-ellipsis3 {
  0%,
  25% {
    left: 32px;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    left: 32px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  75% {
    left: 100px;
  }
  100% {
    left: 168px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  25%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}
@-webkit-keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  25%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}
@keyframes lds-ellipsis {
  0% {
    left: 32px;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  25% {
    left: 32px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    left: 100px;
  }
  75% {
    left: 168px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    left: 168px;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}
@-webkit-keyframes lds-ellipsis {
  0% {
    left: 32px;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  25% {
    left: 32px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    left: 100px;
  }
  75% {
    left: 168px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    left: 168px;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}
.lds-ellipsis {
  position: relative;
}

.lds-ellipsis > div {
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 32px;
  height: 32px;
}

.lds-ellipsis div > div {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #f00;
  position: absolute;
  top: 100px;
  left: 32px;
  -webkit-animation: lds-ellipsis 1.5s cubic-bezier(0, 0.5, 0.5, 1) infinite
    forwards;
  animation: lds-ellipsis 1.5s cubic-bezier(0, 0.5, 0.5, 1) infinite forwards;
}

.lds-ellipsis div:nth-child(1) div {
  -webkit-animation: lds-ellipsis2 1.5s cubic-bezier(0, 0.5, 0.5, 1) infinite
    forwards;
  animation: lds-ellipsis2 1.5s cubic-bezier(0, 0.5, 0.5, 1) infinite forwards;
  background: #edb426;
}

.lds-ellipsis div:nth-child(2) div {
  -webkit-animation-delay: -0.75s;
  animation-delay: -0.75s;
  background: #1d150a;
}

.lds-ellipsis div:nth-child(3) div {
  -webkit-animation-delay: -0.375s;
  animation-delay: -0.375s;
  background: #edb426;
}

.lds-ellipsis div:nth-child(4) div {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  background: #1d150a;
}

.lds-ellipsis div:nth-child(5) div {
  -webkit-animation: lds-ellipsis3 1.5s cubic-bezier(0, 0.5, 0.5, 1) infinite
    forwards;
  animation: lds-ellipsis3 1.5s cubic-bezier(0, 0.5, 0.5, 1) infinite forwards;
  background: #edb426;
}

.lds-ellipsis {
  width: 80px !important;
  height: 80px !important;
  -webkit-transform: translate(-40px, -40px) scale(0.4) translate(40px, 40px);
  transform: translate(-40px, -40px) scale(0.4) translate(40px, 40px);
}

/*# sourceMappingURL=PaymentLoading.css.map */
